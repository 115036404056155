import React, { useContext } from "react"
import Hero from "~/components/configurable/Hero"
import Block from "~/components/configurable/Block"
import Heading from "~/components/configurable/Heading"
import BlockCTA from "~/components/configurable/BlockCTA"
import Col6 from "~/components/grid/Col6"
import { window } from 'global';
import { CustomerTypeContext } from "~/providers/CustomerTypeProvider"
import Section from "../configurable/Section"
import Col12 from "../grid/Col12"
import "./blank.scss"

const AppMonitoring = ({ location }) => {

  return (
    <div className="blank">
      <Hero imageUrl="/images/banner_new_2.png" compact>
        <Heading level={1} underlined>
          App Monitoring
        </Heading>
      </Hero>
      <Block className="segBlock">
        <Section>
          <div className="container container--column ">
            <Col12>
              <Heading level={1}>App Monitoring</Heading>
                <p className="bold-formatting">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam sed, deserunt voluptate consectetur expedita eveniet mollitia, inventore deleniti est numquam accusantium voluptas laudantium ipsum dicta suscipit molestias vero in optio.
                </p>
            </Col12>
          </div>
        </Section>     
      </Block>
    </div>
  )
}

export default AppMonitoring
